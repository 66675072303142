import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// no lazy loading for auth pages to avoid flickering
const AuthLayout = React.lazy(() => import('@app/components/layouts/AuthLayout/AuthLayout'));
import LoginPage from '@app/pages/LoginPage';
import SignUpPage from '@app/pages/SignUpPage';
import ForgotPasswordPage from '@app/pages/ForgotPasswordPage';
import SecurityCodePage from '@app/pages/SecurityCodePage';
import NewPasswordPage from '@app/pages/NewPasswordPage';
import LockPage from '@app/pages/LockPage';

import MainLayout from '@app/components/layouts/main/MainLayout/MainLayout';
import ProfilePage from '@app/pages/ProfilePage';
import RequireAuth from '@app/components/router/RequireAuth';
import { withLoading } from '@app/hocs/withLoading.hoc';

import DashboardPage from '@app/pages/DashboardPages/DashboardPage';

const CustomersPage = React.lazy(() => import('@app/pages/CustomersPage'));
const CustomerViewPage = React.lazy(() => import('@app/pages/CustomerViewPage'));
const ServicesPage = React.lazy(() => import('@app/pages/ServicesPage'));
const ServiceViewPage = React.lazy(() => import('@app/pages/ServiceViewPage'));
const ServiceSchedulesPage = React.lazy(() => import('@app/pages/ServiceSchedulesPage'));
const InventoryPage = React.lazy(() => import('@app/pages/InventoryPage'));
const InventoryItemViewPage = React.lazy(() => import('@app/pages/InventoryItemViewPage'));
const InspectionsPage = React.lazy(() => import('@app/pages/InspectionsPage'));
const InspectionViewPage = React.lazy(() => import('@app/pages/InspectionViewPage'));
const InspectionEditPage = React.lazy(() => import('@app/pages/InspectionEditPage'));
const UsersPage = React.lazy(() => import('@app/pages/UsersPage'));
const UserViewPage = React.lazy(() => import('@app/pages/UserViewPage'));
const InvoicesPage = React.lazy(() => import('@app/pages/InvoicesPage'));
const InvoiceViewPage = React.lazy(() => import('@app/pages/InvoiceViewPage'));
const SettingPage = React.lazy(() => import('@app/pages/SettingPage'));

const PersonalInfoPage = React.lazy(() => import('@app/pages/ProfilePages/ProfilePersonal'));
const PasswordSettingsPage = React.lazy(() => import('@app/pages/ProfilePages/ProfilePassword'));

import MedicalDashboardPage from '@app/pages/DashboardPages/MedicalDashboardPage';

const NewsFeedPage = React.lazy(() => import('@app/pages/NewsFeedPage'));
const ChartsPage = React.lazy(() => import('@app/pages/ChartsPage'));
const ServerErrorPage = React.lazy(() => import('@app/pages/ServerErrorPage'));
const Error404Page = React.lazy(() => import('@app/pages/Error404Page'));
const AdvancedFormsPage = React.lazy(() => import('@app/pages/AdvancedFormsPage'));

const ButtonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/ButtonsPage'));
const SpinnersPage = React.lazy(() => import('@app/pages/uiComponentsPages/SpinnersPage'));
const AvatarsPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/AvatarsPage'));
const BadgesPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/BadgesPage'));
const CollapsePage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/CollapsePage'));
const PaginationPage = React.lazy(() => import('@app/pages/uiComponentsPages/dataDisplay/PaginationPage'));
const ModalsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/ModalsPage'));
const PopoversPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopoversPage'));
const PopconfirmsPage = React.lazy(() => import('@app/pages/uiComponentsPages/modals/PopconfirmsPage'));
const ProgressPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ProgressPage'));
const ResultsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/ResultsPage'));
const AlertsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/AlertsPage'));
const SkeletonsPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/SkeletonsPage'));
const InputsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/InputsPage'));
const CheckboxesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/CheckboxesPage'));
const RadiosPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RadiosPage'));
const SelectsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SelectsPage'));
const SwitchesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/SwitchesPage'));
const UploadsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/UploadsPage'));
const RatesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/RatesPage'));
const AutoCompletesPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/AutoCompletesPage'));
const StepsPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/StepsPage'));
const DateTimePickersPage = React.lazy(() => import('@app/pages/uiComponentsPages/forms/DateTimePickersPage'));
const DropdownsPage = React.lazy(() => import('@app/pages/uiComponentsPages/DropdownsPage'));
const BreadcrumbsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/BreadcrumbsPage'));
const TabsPage = React.lazy(() => import('@app/pages/uiComponentsPages/navigation/TabsPage'));
const NotificationsUIPage = React.lazy(() => import('@app/pages/uiComponentsPages/feedback/NotificationsPage'));
const Logout = React.lazy(() => import('./Logout'));

export const ROOT_PATH = '/';
export const MEDICAL_DASHBOARD_PATH = '/medical-dashboard';

const Dashboard = withLoading(DashboardPage);
const Customers = withLoading(CustomersPage);
const CustomerView = withLoading(CustomerViewPage);
const Services = withLoading(ServicesPage);
const ServiceView = withLoading(ServiceViewPage);
const ServiceSchedules = withLoading(ServiceSchedulesPage);
const Inventory = withLoading(InventoryPage);
const InventoryItemView = withLoading(InventoryItemViewPage);
const Inspections = withLoading(InspectionsPage);
const InspectionView = withLoading(InspectionViewPage);
const InspectionEdit = withLoading(InspectionEditPage);
const Users = withLoading(UsersPage);
const UserView = withLoading(UserViewPage);
const Invoices = withLoading(InvoicesPage);
const InvoiceView = withLoading(InvoiceViewPage);
const Settings = withLoading(SettingPage);

const MedicalDashboard = withLoading(MedicalDashboardPage);
const NewsFeed = withLoading(NewsFeedPage);
const AdvancedForm = withLoading(AdvancedFormsPage);

// UI Components
const Buttons = withLoading(ButtonsPage);
const Spinners = withLoading(SpinnersPage);
const Inputs = withLoading(InputsPage);
const Checkboxes = withLoading(CheckboxesPage);
const Radios = withLoading(RadiosPage);
const Selects = withLoading(SelectsPage);
const Switches = withLoading(SwitchesPage);
const Uploads = withLoading(UploadsPage);
const Rates = withLoading(RatesPage);
const AutoCompletes = withLoading(AutoCompletesPage);
const Steps = withLoading(StepsPage);
const DateTimePickers = withLoading(DateTimePickersPage);
const Dropdowns = withLoading(DropdownsPage);
const Breadcrumbs = withLoading(BreadcrumbsPage);
const Tabs = withLoading(TabsPage);
const Avatars = withLoading(AvatarsPage);
const Badges = withLoading(BadgesPage);
const Collapse = withLoading(CollapsePage);
const Pagination = withLoading(PaginationPage);
const Modals = withLoading(ModalsPage);
const Popovers = withLoading(PopoversPage);
const Popconfirms = withLoading(PopconfirmsPage);
const Progress = withLoading(ProgressPage);
const Results = withLoading(ResultsPage);
const Alerts = withLoading(AlertsPage);
const NotificationsUI = withLoading(NotificationsUIPage);
const Skeletons = withLoading(SkeletonsPage);
const Charts = withLoading(ChartsPage);

const ServerError = withLoading(ServerErrorPage);
const Error404 = withLoading(Error404Page);

// ProfilePersonal
const PersonalInfo = withLoading(PersonalInfoPage);
const PasswordSettings = withLoading(PasswordSettingsPage);

const AuthLayoutFallback = withLoading(AuthLayout);
const LogoutFallback = withLoading(Logout);

export const AppRouter: React.FC = () => {
  const protectedLayout = (
    <RequireAuth>
      <MainLayout />
    </RequireAuth>
  );

  return (
    <BrowserRouter>
      <Routes>
        <Route path={ROOT_PATH} element={protectedLayout}>
          <Route index element={<Dashboard />} />

          <Route path="customers">
            <Route path="" element={<Customers />} />
            <Route path="customer/:id" element={<CustomerView />} />
          </Route>

          <Route path="services">
            <Route path="" element={<Services />} />
            <Route path="service/:id" element={<ServiceView />} />
            <Route path="schedule" element={<ServiceSchedules />} />
          </Route>

          <Route path="invoices">
            <Route path="" element={<Invoices />} />
            <Route path="invoice/:id" element={<InvoiceView />} />
          </Route>

          <Route path="inventory">
            <Route path="" element={<Inventory />} />
            <Route path="item/:id" element={<InventoryItemView />} />
          </Route>

          <Route path="inspections">
            <Route path="" element={<Inspections />} />
            <Route path="inspection/:id" element={<InspectionView />} />
            <Route path="inspection/edit/:id" element={<InspectionEdit />} />
          </Route>

          <Route path="users" element={<Users />} />
          <Route path="user/:id" element={<UserView />} />

          <Route path="settings" element={<Settings />} />

          <Route path="profile" element={<ProfilePage />}>
            <Route path="personal-information" element={<PersonalInfo />} />
            <Route path="security-settings" element={<PasswordSettings />} />
          </Route>

          <Route path={MEDICAL_DASHBOARD_PATH} element={<MedicalDashboard />} />
          <Route path="apps">
            <Route path="feed" element={<NewsFeed />} />
          </Route>
          <Route path="forms">
            <Route path="advanced-forms" element={<AdvancedForm />} />
          </Route>
          <Route path="charts" element={<Charts />} />
          <Route path="server-error" element={<ServerError />} />
          <Route path="404" element={<Error404 />} />
          <Route path="ui-components">
            <Route path="button" element={<Buttons />} />
            <Route path="spinner" element={<Spinners />} />
            <Route path="input" element={<Inputs />} />
            <Route path="checkbox" element={<Checkboxes />} />
            <Route path="radio" element={<Radios />} />
            <Route path="select" element={<Selects />} />
            <Route path="switch" element={<Switches />} />
            <Route path="upload" element={<Uploads />} />
            <Route path="rate" element={<Rates />} />
            <Route path="auto-complete" element={<AutoCompletes />} />
            <Route path="steps" element={<Steps />} />
            <Route path="date-time-picker" element={<DateTimePickers />} />
            <Route path="dropdown" element={<Dropdowns />} />
            <Route path="breadcrumbs" element={<Breadcrumbs />} />
            <Route path="tabs" element={<Tabs />} />
            <Route path="avatar" element={<Avatars />} />
            <Route path="badge" element={<Badges />} />
            <Route path="collapse" element={<Collapse />} />
            <Route path="pagination" element={<Pagination />} />
            <Route path="modal" element={<Modals />} />
            <Route path="popover" element={<Popovers />} />
            <Route path="popconfirm" element={<Popconfirms />} />
            <Route path="progress" element={<Progress />} />
            <Route path="result" element={<Results />} />
            <Route path="alert" element={<Alerts />} />
            <Route path="notification" element={<NotificationsUI />} />
            <Route path="skeleton" element={<Skeletons />} />
          </Route>
        </Route>
        <Route path="/auth" element={<AuthLayoutFallback />}>
          <Route path="login" element={<LoginPage />} />
          <Route path="sign-up" element={<SignUpPage />} />
          <Route
            path="lock"
            element={
              <RequireAuth>
                <LockPage />
              </RequireAuth>
            }
          />
          <Route path="forgot-password" element={<ForgotPasswordPage />} />
          <Route path="security-code" element={<SecurityCodePage />} />
          <Route path="new-password" element={<NewPasswordPage />} />
        </Route>
        <Route path="/logout" element={<LogoutFallback />} />
      </Routes>
    </BrowserRouter>
  );
};
