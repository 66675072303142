import React from 'react';
import { SearchFilter } from '../SearchFilter/SearchFilter';
import { SearchResults } from '../SearchResults/SearchResults';
import { NotFound } from '@app/components/common/NotFound/NotFound';
import * as S from './SearchOverlay.styles';
import { SearchResult } from '@app/api/summary.api';
import { SearchCategoryType } from '@app/constants/categoriesList';
import { Spinner } from '@app/components/common/Spinner/Spinner';

interface SearchOverlayProps {
  data: SearchResult[] | null;
  isFilterOpen: boolean;
  isSearching: boolean;
  onSelectType: (type: SearchCategoryType) => void;
}

export const SearchOverlay: React.FC<SearchOverlayProps> = ({ data, isFilterOpen, isSearching, onSelectType }) => {
  return (
    <S.Menu>
      <SearchFilter data={data} isOpen={isFilterOpen} onSelectType={onSelectType}>
        {(filteredResults) =>
          isSearching ? (
            <Spinner size="default" tip="Searching..." />
          ) : filteredResults.length > 0 ? (
            <SearchResults results={filteredResults} />
          ) : (
            <NotFound />
          )
        }
      </SearchFilter>
    </S.Menu>
  );
};
