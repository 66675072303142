import { Radio } from 'antd';
import styled, { css } from 'styled-components';

interface FilterWrapperProps {
  isOpen: boolean;
}

export const CheckboxGroup = styled(Radio.Group)`
  display: block;
`;

export const FilterWrapper = styled.div<FilterWrapperProps>`
  ${(props) =>
    !props.isOpen &&
    css`
      position: absolute;
      top: 0;
      opacity: 0;
    `}

  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`;

export const RadioOption = styled(Radio)`
  font-size: 1rem;
  margin-bottom: 0.5rem;
`;
