import React, { useEffect, useMemo, useState } from 'react';
import { Col, Row } from 'antd';
import { categoriesList, SearchCategoryType } from 'constants/categoriesList';
import * as S from './SearchFilter.styles';
import { RadioChangeEvent } from '@app/components/common/Radio/Radio';
import { SearchResult } from '@app/api/summary.api';

interface SearchFilterProps {
  data: SearchResult[] | null;
  isOpen: boolean;
  children: (filteredResults: SearchResult[]) => React.ReactNode;
  onSelectType: (type: SearchCategoryType) => void;
}

export const SearchFilter: React.FC<SearchFilterProps> = ({ data, isOpen, children, onSelectType }) => {
  const [filteredResults, setFilteredResults] = useState<SearchResult[] | null>(data);

  const filterElements = useMemo(
    () =>
      categoriesList.map((filter, index) => (
        <Col key={index} xs={24} sm={12} xl={8} style={{ padding: 5 }}>
          <S.RadioOption key={index} value={filter.name}>
            {filter.title}
          </S.RadioOption>
        </Col>
      )),
    [],
  );

  const onChange = (e: RadioChangeEvent) => {
    onSelectType(e.target.value);
  };

  useEffect(() => {
    if (data) {
      setFilteredResults(data);
    } else {
      setFilteredResults(null);
    }
  }, [data]);

  return (
    <>
      <S.FilterWrapper isOpen={isOpen}>
        <S.CheckboxGroup onChange={onChange} defaultValue={'services'}>
          <Row>{filterElements}</Row>
        </S.CheckboxGroup>
      </S.FilterWrapper>

      {filteredResults && children(filteredResults)}
    </>
  );
};
