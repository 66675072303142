export const BORDER_RADIUS = '7px';

export const BASE_COLORS = {
  white: '#ffffff',
  black: '#000000',
  green: '#008000',
  orange: '#ffb155',
  gray: '#808080',
  lightgrey: '#c5d3e0',
  violet: '#ee82ee',
  lightgreen: '#89dca0',
  pink: '#ffc0cb',
  blue: '#0000ff',
  skyblue: '#35a0dc',
  red: '#ff5252',
};

export const primaryGreen = {
  50: '#e1ece0',
  100: '#b5ceb3',
  200: '#84ae80',
  300: '#528e4d',
  400: '#2d7526',
  500: '#085d00',
  600: '#075500',
  700: '#064b00',
  800: '#044100',
  900: '#023000',
  A100: '#69ff69',
  A200: '#36ff36',
  A400: '#03ff03',
  A700: '#00e800',
};

export const primaryYellow = {
  50: '#fff6e1',
  100: '#ffe9b3',
  200: '#ffdb80',
  300: '#ffcd4d',
  400: '#ffc227',
  500: '#ffb701',
  600: '#ffb001',
  700: '#ffa701',
  800: '#ff9f01',
  900: '#ff9000',
  A100: '#ffffff',
  A200: '#fff9f2',
  A400: '#ffe0bf',
  A700: '#ffd4a6',
};

export const primaryRed = {
  50: '#f9e0e0',
  100: '#f1b3b3',
  200: '#e88080',
  300: '#de4d4d',
  400: '#d72626',
  500: '#d00000',
  600: '#cb0000',
  700: '#c40000',
  800: '#be0000',
  900: '#b30000',
  A100: '#ffdcdc',
  A200: '#ffa9a9',
  A400: '#ff7676',
  A700: '#ff5d5d',
};

export const primaryLight = {
  50: '#fefefe',
  100: '#fdfdfd',
  200: '#fbfbfb',
  300: '#f9f9f9',
  400: '#f8f8f8',
  500: '#f7f7f7',
  600: '#f6f6f6',
  700: '#f5f5f5',
  800: '#f3f3f3',
  900: '#f1f1f1',
};

export const primaryDark = {
  50: '#e3e3e3',
  100: '#b8b8b8',
  200: '#898989',
  300: '#595959',
  400: '#363636',
  450: '#1e1e1e',
  500: '#121212',
  600: '#101010',
  700: '#0d0d0d',
  800: '#0a0a0a',
  900: '#050505',
};

export const primaryBlue = {
  50: '#e4e4e8',
  100: '#bcbcc6',
  200: '#8f90a1',
  300: '#62647b',
  400: '#40425e',
  500: '#1e2142',
  600: '#1a1d3c',
  700: '#161833',
  800: '#12142b',
  900: '#0a0b1d',
  A100: '#5d69ff',
  A200: '#2a3aff',
  A400: '#0012f6',
  A700: '#0010dd',
};

export const LAYOUT = {
  mobile: {
    paddingVertical: '0.75rem',
    paddingHorizontal: '1rem',
    headerHeight: '4.25rem',
    headerPadding: '1rem',
  },
  desktop: {
    paddingVertical: '1.25rem',
    paddingHorizontal: '2.25rem',
    headerHeight: '5.625rem',
  },
};

export const FONT_FAMILY = {
  main: 'Montserrat',
  secondary: 'Lato',
};

export const FONT_SIZE = {
  xxs: '0.75rem',
  xs: '0.875rem',
  md: '1rem',
  lg: '1.125rem',
  xl: '1.25rem',
  xxl: '1.5rem',
  xxxl: '1.625rem',
  xxxxl: '2rem',
};

export const FONT_WEIGHT = {
  thin: '100',
  extraLight: '200',
  light: '300',
  regular: '400',
  medium: '500',
  semibold: '600',
  bold: '700',
  extraBold: '800',
  black: '900',
};

export const BREAKPOINTS = {
  xs: 360,
  sm: 568,
  md: 768,
  lg: 992,
  xl: 1280,
  xxl: 1920,
} as const;

const getMedia = <T extends number>(breakpoint: T): `(min-width: ${T}px)` => `(min-width: ${breakpoint}px)`;

export const media = {
  xs: getMedia(BREAKPOINTS.xs),
  sm: getMedia(BREAKPOINTS.sm),
  md: getMedia(BREAKPOINTS.md),
  lg: getMedia(BREAKPOINTS.lg),
  xl: getMedia(BREAKPOINTS.xl),
  xxl: getMedia(BREAKPOINTS.xxl),
};
